 @tailwind base;
 @tailwind components;
 @tailwind utilities;
:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}
/* force white-colored background for users that prefer dark mode */
@media (prefers-color-scheme: dark) {
  :root {
    /* --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0; */
    /*--background: 255, 255, 255;*/
  }
}
 .footer {
   img.margin-left-4 {
     height: 40px;
   }
 }
body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}
html {
  scroll-behavior: smooth;
}
